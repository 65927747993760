import React from 'react';
import { Card } from 'react-bootstrap';
import "./gameCard.css";
import { useNavigate } from 'react-router-dom';

const gameCard = ({ game }) => {
  const navigate = useNavigate();

  const handleCategoryClick = (game) => {
    navigate(`/game/${game.id}`,{ state: { game } }); 
  };
  
  function getGameTitle(title) {
    title = title.split(" ")
    if(title.length>1){
      title = title[0] + " " + title [1]
    }else if(title.length > 0){
      title= title [0]
    }
    return title
  }
  return (
    <div className="package"  onClick={() =>handleCategoryClick(game)}>
      <div className="package2">
        <Card>
          <Card.Img variant="top" src={game.thumb} className="card-img-top" />
          <Card.Body className='card-body'>
            <Card.Title className='card-title'>{game.title}</Card.Title>
            {/* <Card.Text>{game.description}</Card.Text> */}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default gameCard;
