import React, { useState, useEffect } from "react";
import TopCarousel, { CarouselItem } from "../carousel/carousel";
import GameCarousel from "../carousel/gameCarousel";
import CategoryCard from "../gameCategory/categoryCard";
import LargecategoryCard from "../gameCategory/largecategoryCard";
import ScrollableCategory from '../scrollablecategory/scrollableCategory'
import AdSideBar from "../ad/adSideBar";
import "./home.css"; // Import the CSS file
import "../carousel/carousel";
import Allcategory from "../../data/category.json"
import AllGames from "../../data/gameData.json"
import Loader from "../Loader/loader";

const Home = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [gamesCarousels, setGamesCarousels] = useState([]);
  const [hotGames, sethotGames] = useState([]);
  const [editorPicks, seteditorPicks] = useState([]);
  const [exclusiveGames, setexclusiveGames] = useState([]);
  const [bestGames, setbestGames] = useState([]);
  const [allGames, setAllGameData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [carouselResponse, hotGamesResponse, editorpicksResponse, exclusivegamesResponse, bestgamesResponse] = await Promise.all([
          fetch("https://gamemonetize.com/rssfeed.php?format=json&category=All&type=html5&popularity=mostplayed&company=All&amount=10"),
          fetch("https://gamemonetize.com/rssfeed.php?format=json&category=All&type=html5&popularity=hotgames&company=All&amount=10"),
          fetch("https://gamemonetize.com/rssfeed.php?format=json&category=All&type=html5&popularity=editorpicks&company=All&amount=10"),
          fetch("https://gamemonetize.com/rssfeed.php?format=json&category=All&type=html5&popularity=exclusivegames&company=All&amount=10"),
          fetch("https://gamemonetize.com/rssfeed.php?format=json&category=All&type=html5&popularity=bestgames&company=All&amount=10"),
        ])
        const carouselData = await carouselResponse.json();
        const hotGamesData = await hotGamesResponse.json();
        const editorPicksData = await editorpicksResponse.json();
        const exclusiveGamesData = await exclusivegamesResponse.json();
        const bestGamesData = await bestgamesResponse.json();
        setAllCategories(Allcategory);
        setGamesCarousels(carouselData);
        sethotGames(hotGamesData)
        seteditorPicks(editorPicksData)
        setexclusiveGames(exclusiveGamesData)
        setbestGames(bestGamesData)
        setAllGameData(AllGames.data);
        setLoading(false);


        if (false && allGames.date != new Date().getDate()) {
          const allGameResponse = await fetch(`https://gamemonetize.com/feed.php?format=0&page=1`)
          const allGameData = await allGameResponse.json();
          setAllGameData(allGameData);
        }


      } catch (error) {
        console.error("Failed to fetch data:", error);
        // Optionally handle error (e.g., show an error message to the user)
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (<Loader></Loader>)
  }

  return (
    <div className="container">
      <div className="main-content">
        <div className="content-wrapper">

          <TopCarousel carouselItems={gamesCarousels}></TopCarousel>
          {/* <div className="card-container">
            {allCategories.map((game, index) => {
              if (game.title == "ad") {
                return (<AdSideBar></AdSideBar>)
              } else {
                return (<CategoryCard key={index} game={game} />)
              }

            })}
          </div> */}
          <div className="categoies">
            <ScrollableCategory  category={{title: "Hot Games", id:0}} games={hotGames} />
            <ScrollableCategory category={{title: "Editor Picks", id:0}} games={editorPicks} />
            <ScrollableCategory category={{title: "Exclusive Games", id:0}} games={exclusiveGames} />
            <ScrollableCategory category={{title: "Best Games", id:0}} games={bestGames} />

            {allCategories.map((category, index) => {
              let gamesArray = [];
              allGames.map(game => {
                if (game.category === category.title) {
                  gamesArray.push(game)
                }
              });
              if (gamesArray.length > 0) {
                return (<ScrollableCategory key={index} category={category} games={gamesArray} />)
              }
            })}
          </div>
          {/* <AdSideBar></AdSideBar> */}
        </div>

      </div>
    </div>
  );
};

export default Home;
