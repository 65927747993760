import React, { useRef } from "react";
import "./scrollableCategory.css"; // CSS for styling
import GameCard from "../gamecard/gameCard"; // Assuming GameCard is a component
import { useNavigate } from 'react-router-dom';

const ScrollableCategory = ({ category, games }) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  // Handle navigation on category click
  const handleCategoryClick = (id) => {
    navigate(`/games?c=${id}`);
  };

  // Handle mouse drag start
  const handleMouseDown = (e) => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.isDown = true;
    scrollContainer.startX = e.pageX - scrollContainer.offsetLeft;
    scrollContainer.scrollLeftStart = scrollContainer.scrollLeft;
    e.preventDefault();
  };

  // Handle mouse drag move
  const handleMouseMove = (e) => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer.isDown) return;
    const x = e.pageX - scrollContainer.offsetLeft;
    const walk = (x - scrollContainer.startX) * 1.5; // Adjust scroll speed
    scrollContainer.scrollLeft = scrollContainer.scrollLeftStart - walk;
  };

  // Handle mouse drag end
  const handleMouseUp = () => {
    scrollContainerRef.current.isDown = false;
  };

  // Handle touch start for mobile
  const handleTouchStart = (e) => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.isDown = true;
    scrollContainer.startX = e.touches[0].pageX - scrollContainer.offsetLeft;
    scrollContainer.scrollLeftStart = scrollContainer.scrollLeft;
  };

  // Handle touch move for mobile
  const handleTouchMove = (e) => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer.isDown) return;
    const x = e.touches[0].pageX - scrollContainer.offsetLeft;
    const walk = (x - scrollContainer.startX) * 1.5; // Adjust scroll speed
    scrollContainer.scrollLeft = scrollContainer.scrollLeftStart - walk;
  };

  // Handle touch end for mobile
  const handleTouchEnd = () => {
    scrollContainerRef.current.isDown = false;
  };

  return (
    <div className="category-section">
      <div className="category-header">
        <h2>{category.title}</h2>
        {category.id != 0 &&

          <div onClick={() => handleCategoryClick(category.id)}>
            <button className="see-more-btn">
              See More
            </button>
          </div>
        }
      </div>
      <hr className="category-line" />
      <div
        className="scroll-container"
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="game-cards">
          {games.slice(0, 10).map((game, index) => (
            <GameCard key={index} game={game} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollableCategory;
