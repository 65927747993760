import React, { useState, useEffect } from "react";
import { Card } from 'react-bootstrap';
import "../home/home.css"; // Import the CSS file
import GamePageAd from "../ad/gamePageAd";
import GameTags from "../gameTag/gameTag";
import { useLocation, useParams } from 'react-router-dom';
import "./game.css";
import GameCard from "../gamecard/gameCard";
import Allcategory from "../../data/category.json";
import Loader from "../Loader/loader";

const Game = () => {
    const [loading, setLoading] = useState(true);
    const [gameData, setGameData] = useState({});
    const [similarGames, setsimilarGames] = useState([]);
    const [selectedGameUrl, setSelectedGameUrl] = useState(''); // State for selected game URL for iframe
    const [iframeloading, setIFrameLoading] = useState(false);

    const { gameId } = useParams(); // Get the game ID from the URL
    const location = useLocation();
    const { game } = location.state || {}; // Destructure the game object from state
    // Scroll back to top when the button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        scrollToTop()
        if (!game) {
            fetchGameDetails(gameId);
        } else {
            console.log(typeof game.tags)
            if (typeof game.tags == 'string') {
                game.tags = game.tags.split(",")
            }
            fetchSimilarGames(game.category)
            setGameData(game);
            setLoading(false);
        }


        let id = game.url.split("/");
        console.log(id[id.length - 2])
        window.VIDEO_OPTIONS = {
            gameid: id[id.length - 2],
            width: "100%",
            height: "480px",
            color: "#3f007e"
        };

        // Create the script element and append it to the document
        const script = document.createElement('script');
        script.id = 'gamemonetize-video-api';
        script.src = `https://api.gamemonetize.com/video.js?v=${Date.now()}`;

        // Append the script to the body or head
        const firstScriptTag = document.getElementsByTagName('script')[0];
        if (firstScriptTag && !document.getElementById('gamemonetize-video-api')) {
            firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
        }

        // Cleanup the script when component unmounts
        return () => {
            const scriptTag = document.getElementById('gamemonetize-video-api');
            if (scriptTag) {
                scriptTag.remove();
            }
        };
    }, [game, gameId]);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleGameonClick = (url) => {
        return () => {
            setSelectedGameUrl(url);
            setIFrameLoading(true)
        }// Set the selected game URL to show in iframe
    };

    const fetchGameDetails = async (id) => {
        try {
            const [allGameResponse] = await Promise.all([
                fetch(`http://localhost:3001/games?id=${id}`),
            ]);

            const allGamesData = await allGameResponse.json();
            if (typeof allGamesData.tags == 'string') {
                allGamesData.tags = allGamesData.tags.split(",")
            }
            setGameData(allGamesData[0]);

            if (allGamesData && Array.isArray(allGamesData) && allGamesData.length > 0) {
                let category = allGamesData[0].category
                fetchSimilarGames(category)
            }

            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };

    const fetchSimilarGames = async (category) => {

        let categoryDetails = Allcategory.find(cat => cat.title == category);

        if (categoryDetails) {
            const similarGamesResponse = await fetch(`https://gamemonetize.com/feed.php?format=0&category=${categoryDetails.id}&num=50&page=1&num=20`)
            const similarGames = await similarGamesResponse.json();
            setsimilarGames(similarGames);
        }

    }

    if (loading) {
        return (
            <div className="loading-container">
                Loading...
            </div>
        );
    }


    return (
        <div className="game-page-container">

              {console.log("--",iframeloading)}
            {iframeloading && <div  className="game-card"><Loader></Loader></div>}
            {!selectedGameUrl && 
            (<Card className="game-card" onClick={handleGameonClick(gameData.url)}>
                <Card.Img variant="top" src={gameData.thumb} className="game-card-img" />
                <div className="play-button"></div>
            </Card>)}

            { (selectedGameUrl) && (
                <div className="iframe-container">
                    <iframe
                        src={selectedGameUrl}
                        title="Selected Game"
                        width="100%"
                        height="500px"
                        frameBorder="0"
                        allowFullScreen
                        allow="fullscreen; autoplay; encrypted-media;" // Allows fullscreen, autoplay, etc.
                        onLoad={() => setIFrameLoading(false)}
                    ></iframe>
                </div>
            )}
          

            {/* <GamePageAd></GamePageAd> */}

            <div className="game-info">
                <h1 className="game-title">{gameData.title}</h1>
                <GameTags tags={gameData.tags} />
                <p className="game-instructions">Instructions: {gameData.instructions}</p>
                <p className="game-description">Description: {gameData.description}</p>
            </div>
            <p className="similar-games">Walkthrogh</p>

            <div id="gamemonetize-video"></div>

            <p className="similar-games">Similar games</p>


            <div className="card-container">
                {similarGames.map((game, index) => {
                    if (game.id != gameData.id) {
                        if (game.title == "ad") {
                            return (<AdSideBar></AdSideBar>)
                        } else {
                            return (<GameCard key={index} game={game} />)
                        }
                    }
                }
                )}
            </div>



        </div>
    );
};

export default Game;
