// TopCarousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import './carousel.css'; // Optional custom styles
import { useNavigate } from 'react-router-dom';

const TopCarousel = ({ carouselItems }) => {
  const navigate = useNavigate();

  const handleSlideClick = (game) => {
    console.log(game)
    navigate(`/game/${game.id}`, { state: { game } });
  };
  return (
    <div className="carousel-container">
      <Carousel
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showStatus={false}
        stopOnHover={false}
        swipeable={true}
        emulateTouch={true}
        showArrows={true}
      >
        {carouselItems.map((game, index) => (
          <div onClick={() => handleSlideClick(game)}>
            <img src={game.thumb} alt={game.title}/>
            <p className="legend">{game.title}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TopCarousel;
