import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Allcategory from "../../data/category.json"
import "../home/home.css"; // Import the CSS file
import "./Loader.css"; // Import the CSS file

const Loader = () => {
    return (
        <div className="container">
            <div className="main-content">
                <div className="content-wrapper">
                    <div className="loader">
                        <div className="box1"></div>
                        <div className="box2"></div>
                        <div className="box3"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;
