import React, { useEffect, useState, useRef, useCallback } from 'react';
import GameCard from "../gamecard/gameCard";
import "../home/home.css"; // Import the CSS file
import AdSideBar from "../ad/adSideBar";
import Loader from "../Loader/loader";

const Home = () => {
  const [allAgames, setAllgames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Page count for pagination
  const [hasMore, setHasMore] = useState(true); // Flag to determine if more data is available

  const observerRef = useRef(); // Ref to hold the observer

  const fetchData = async (pageNumber) => {
    setLoading(true);
    try {
      const urlSearchString = window.location.search;

      const params = new URLSearchParams(urlSearchString);
      let category = ''
      if(params.get('c')!=0){
        category = `&category=${params.get('c')}`
      }
      const [allGameResponse] = await Promise.all([
        fetch(`https://gamemonetize.com/feed.php?format=0${category}&num=20&page=${pageNumber}`),
      ]);     
      const allGamesData = await allGameResponse.json();
      setAllgames((prevData) => [...prevData, ...allGamesData]); // Append new data to the existing data
      setHasMore(allGamesData.length > 0); // Check if there is more data
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

   // Callback for intersection observer
   const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page when bottom is reached
      }
    });

    if (node) observerRef.current.observe(node); // Observe the last element
  }, [loading, hasMore]);

  // Fetch data when page changes
  useEffect(() => {
    fetchData(page);
  }, [page]);

  return (
    <div className="container">
      <div className="main-content">
        <div className="content-wrapper">

          <div className="card-container">
            {allAgames.map((game, index) => {
              if (game.title == "ad") {
                return (<AdSideBar></AdSideBar>)
              } else {
                return (<GameCard key={index} game={game} />)
              }
            }

            )}
          </div>
        </div>
      </div>
      <div ref={lastElementRef} className="loading">
        <Loader/>
      </div>
    </div>
  );
};

export default Home;
