import React from "react";
import { useNavigate } from "react-router-dom"; // If using react-router
import "./backButton.css";

const BackButton = () => {
    const navigate = useNavigate(); // Use useNavigate

    const goBack = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <button className="back-button" onClick={goBack}>
            ← Back
        </button>
    );
};

export default BackButton;
