import React from 'react';
import './about.css';

const About = () => {
  return (

    <div class="about-us">
      <h1>About Us</h1>
      <p>
        Welcome to our gaming platform, where fun meets innovation! We are dedicated to bringing you the best online gaming experience with a wide variety of games designed for all ages. Our mission is to create a space where everyone can relax, enjoy, and connect through the power of games.
      </p>
      <p>
        Founded by a passionate team of developers, our platform was built with the player in mind. We believe in continuous improvement and regularly update our game library to keep things fresh and exciting.
      </p>
      <p>
        Whether you’re here to unwind after a long day or challenge yourself to reach new heights, we’ve got something for you. Join our community and discover the endless possibilities of online gaming!
      </p>
      <p class="cta">
        <a href="/" class="explore-games">Explore Our Games</a>
      </p>
    </div>


  );
}

export default About;
