import React from "react";
import "./gameTag.css"; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import Allcategory from "../../data/category.json";

const GameTags = ({ tags }) => {
    const navigate = useNavigate();

    const handleCategoryClick = (id) => {
        navigate(`/games?c=${id}`);
    };
    return (
        <div className="tags-container">
            {tags.map((tag, index) => {
                let categoryDetails = Allcategory.find(cat => cat.title == tag);
                if (categoryDetails) {
                    return (
                        <button key={index} className={`tag tag-${tag.toLowerCase()}`} onClick={() => handleCategoryClick(categoryDetails.id)}>
                            {tag}
                        </button>
                    )
                }
            })}
        </div>
    );
};

export default GameTags;
