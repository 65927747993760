import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import "./navbar.css";
import Allcategory from "../../data/category.json"

const NavigationBar = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAllCategories(Allcategory);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        // Optionally handle error (e.g., show an error message to the user)
      }
    };
    fetchData();
  }, []);

  // if (loading) {
  //   return (
  //     <div className="loading-container">
  //       Loading...
  //     </div>
  //   );
  // }

  return (
    <Navbar expand="lg" className="navbar">
      <Navbar.Brand href="/" className="logo">
       <img src="/browserarenaicon.png" alt="browserarena.com"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto navbar-links">
          <Nav.Link href="/" className="navbar-section">Home</Nav.Link>
          <NavDropdown title="Categories" id="basic-nav-dropdown" className="navbar-section">
            {allCategories.map((game, index) => {
              if (game.title != "ad") {
                return(<NavDropdown.Item href={`/games?c=${game.id}`} key={index}>{game.title}</NavDropdown.Item>)
              }
            }
            )}
          </NavDropdown>
          {/* <Nav.Link href="/suggest" className="navbar-section">Suggest</Nav.Link> */}
          <Nav.Link href="/about" className="navbar-section">About Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
