import React from 'react';

const AdSideBar = () => {

    return (
        <aside className="ad-sidebar">
            <div>
                {/* Example placeholder for an ad */}
                <div style={{ width: "100%", height: "250px", backgroundColor: "#444", display: "flex", justifyContent: "center", alignItems: "center", color: "#ddd" }}>
                </div>
            </div>
        </aside>
    );
}

export default AdSideBar;
