import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Home from './components/home/home';
import About from './components/about/about';
import TopBar from './components/navbar/navbar';
import Games from './components/games/games';
import Game from './components/play/game';
import BackButton from "./components/backButton/backbutton";
import Footer from "./components/footer/footer"; // Import the Footer component

function App() {
  return (
    <Router>
       <Helmet>
        <title>Play Browser Games | Free Online Games</title>
        <meta name="description" content="Play the best browser games and explore various online game categories." />
        <meta name="keywords" content="browser games, games, online games, free games" />
      </Helmet>
      <TopBar />
      <BackButton />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/games" element={<Games />} />
        <Route path="/game/:gameId" element={<Game />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
