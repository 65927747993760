import React from "react";
import "./footer.css"; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* <div className="footer-section about">
          <h3>About Us</h3>
          <p>browserarena.com is your go-to platform for exciting and diverse games. Explore new categories, suggest games, and have fun!</p>
        </div> */}
        
        <div className="footer-section links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/games?c=0">Categories</a></li>
            {/* <li><a href="/suggest">Suggest a Game</a></li> */}
          </ul>
        </div>

        <div className="footer-section social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; 2024 browserarena.com | All rights reserved.</p>
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </div>
    </footer>
  );
};

export default Footer;
